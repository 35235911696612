import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { VoiceChatPlayground } from '../components/VoiceChat/VoiceChatPlayground';
import { VoiceChatProvider } from '../components/VoiceChat/VoiceChatProvider';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';

export function Component() {
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
        <AdminToolkitNav />

        <VoiceChatProvider>
          <VoiceChatPlayground />
        </VoiceChatProvider>
      </AdminView>
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}

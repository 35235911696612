import { useEffect, useRef, useState } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { releaseMediaStream } from '../../utils/media';
import {
  MicVolumeMeter,
  MicVolumeMeterProcessor,
} from '../../utils/MicVolumeMeter';
import { type Option, Select } from '../common/Utilities';
import { getUserMedia, useDeviceState } from '../Device';
import {
  DeviceCheckContextProvider,
  DeviceSelector,
  useDeviceLoaded,
} from '../Device/Check';
import { MicrophoneWithVolumeBar } from '../MediaControls';
import {
  type RealtimeEvent,
  type TurnEndMode,
  useVoiceChatAPI,
  useVoiceChatState,
} from './VoiceChatProvider';

function EventEntry(props: { event: RealtimeEvent; expand: boolean }) {
  const api = useVoiceChatAPI();
  const count = props.event.count;
  const event = { ...props.event.event };
  if (event.type === 'input_audio_buffer.append') {
    event.audio = `[trimmed: ${event.audio.length} bytes]`;
  } else if (event.type === 'response.audio.delta') {
    event.delta = `[trimmed: ${event.delta.length} bytes]`;
  }
  return (
    <div className='flex items-start'>
      <div className='w-20 text-secondary flex-shrink-0'>
        {api.formatTime(props.event.time)}
      </div>
      <div>
        <div
          className={`flex items-center gap-2 hover:bg-secondary cursor-pointer ${
            event.type === 'error'
              ? 'text-red-002'
              : props.event.source === 'client'
              ? 'text-primary'
              : 'text-tertiary'
          }`}
          onClick={() => api.toggleExpandEvent(event.event_id)}
        >
          {props.event.source === 'client' ? '↑' : '↓'}
          <div>{props.event.source}</div>
          <div>
            {event.type}
            {count && ` (${count})`}
          </div>
        </div>
        {props.expand && (
          <div className='whitespace-pre'>{JSON.stringify(event, null, 2)}</div>
        )}
      </div>
    </div>
  );
}

function ItemEntry(props: { item: { id: string } }) {
  const api = useVoiceChatAPI();
  const item = api.getItem(props.item.id);

  if (!item) return null;

  return (
    <div className='flex items-center gap-2'>
      <div className='w-20 flex-shrink-0'>{item.role}</div>
      <div>
        {/* tool response */}
        {item.type === 'function_call_output' && (
          <div>{item.formatted.output}</div>
        )}
        {/* tool call */}
        {!!item.formatted.tool && (
          <div>
            {item.formatted.tool.name}({item.formatted.tool.arguments})
          </div>
        )}
        {!item.formatted.tool && item.role === 'user' && (
          <div>
            {item.formatted.transcript ||
              (item.formatted.audio?.length
                ? '(awaiting transcript)'
                : item.formatted.text || '(item sent)')}
          </div>
        )}
        {!item.formatted.tool && item.role === 'assistant' && (
          <div>
            {item.formatted.transcript || item.formatted.text || '(truncated)'}
          </div>
        )}
        {item.formatted.file && (
          <audio src={item.formatted.file.url} controls />
        )}
      </div>
    </div>
  );
}

function DeviceSelectorContainer(props: { onLoaded?: () => void }) {
  useDeviceLoaded(props.onLoaded);
  return (
    <DeviceSelector
      width='w-full'
      cameraSelectEnabled={false}
      cameraSelectVisible={false}
    />
  );
}

function DeviceCheck(props: { onContinue: (deviceId: string) => void }) {
  const [loaded, setLoaded] = useState(false);
  const { activeAudioInputDeviceOption } = useDeviceState();
  return (
    <div className='flex items-center justify-center text-white'>
      <div className='w-160 border border-secondary rounded-xl p-4 flex flex-col gap-8'>
        <h1 className='text-2xl text-center font-bold'>
          Welcome! Check your settings before you start.
        </h1>
        <DeviceCheckContextProvider>
          <DeviceSelectorContainer onLoaded={() => setLoaded(true)} />
        </DeviceCheckContextProvider>
        <button
          type='button'
          className='btn-primary w-50 h-10 self-center'
          disabled={!activeAudioInputDeviceOption || !loaded}
          onClick={() => {
            if (!activeAudioInputDeviceOption) return;
            props.onContinue(activeAudioInputDeviceOption.value);
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

const modeOptions: Option<TurnEndMode>[] = [
  { label: 'Push To Talk', value: 'push_to_talk' },
  { label: 'Auto Detect Voice', value: 'server_vad' },
];

export function VoiceChatPlayground() {
  const api = useVoiceChatAPI();
  const state = useVoiceChatState();
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  const convoRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);
  const [processor, setProcessor] = useState<
    { instance: MicVolumeMeterProcessor; stream: MediaStream } | undefined
  >(undefined);
  const [editingInstructions, setEditingInstructions] = useState(false);

  useEffect(() => {
    return api.init();
  }, [api]);

  useEffect(() => {
    if (convoRef.current) {
      convoRef.current.scrollTop = convoRef.current.scrollHeight;
    }
  }, [state.items.length]);

  useEffect(() => {
    if (eventsRef.current) {
      eventsRef.current.scrollTop = eventsRef.current.scrollHeight;
    }
  }, [state.events.length]);

  const releaseMeter = useLiveCallback(() => {
    processor?.instance.close();
    releaseMediaStream(processor?.stream);
  });

  const updateMeter = useLiveCallback(async (deviceId?: string) => {
    releaseMeter();
    const stream = await getUserMedia({ audio: { deviceId } });
    const instance = new MicVolumeMeterProcessor(new MicVolumeMeter(stream));
    instance.start();
    setProcessor({ stream, instance });
  });

  useEffect(() => {
    if (!deviceId) return;
    updateMeter(deviceId);
  }, [deviceId, updateMeter]);

  const connect = () => {
    if (state.status === 'connected') {
      api.disconnect();
      return;
    }
    if (!deviceId) return;
    api.connect(deviceId, 'Hello');
  };

  if (!deviceId) {
    return <DeviceCheck onContinue={setDeviceId} />;
  }

  return (
    <div className='w-full flex-grow max-h-[85%] flex text-white text-sms gap-6'>
      <div className='flex flex-col w-3/5 h-full border border-secondary rounded-xl p-2'>
        <label className='font-bold'>Conversation</label>
        <div ref={convoRef} className='flex-grow overflow-scroll'>
          <div className='flex flex-col gap-4'>
            {state.items.map((item) => (
              <ItemEntry key={item.key} item={item} />
            ))}
          </div>
        </div>
        <div className='flex items-center'>
          <div className='w-1/3'>
            <div className='flex items-center gap-2'>
              <span>Mode:</span>
              <div className='w-40'>
                <Select
                  options={modeOptions}
                  onChange={(val) => api.changeTurnEndMode(val.value)}
                  value={
                    modeOptions.find((o) => o.value === state.turnEndMode) ??
                    modeOptions[0]
                  }
                />
              </div>
              <MicrophoneWithVolumeBar processor={processor?.instance} />
            </div>
          </div>
          <div className='w-1/3 text-center'>
            {state.turnEndMode === 'push_to_talk' &&
              state.status === 'connected' && (
                <button
                  type='button'
                  className='btn-primary w-30 h-10'
                  onMouseDown={() => api.startRecording()}
                  onMouseUp={() => api.stopRecording()}
                >
                  {state.isRecording ? 'release to send' : 'push to talk'}
                </button>
              )}
          </div>
          <div className='w-1/3 text-right'>
            <button
              type='button'
              onClick={connect}
              className={`w-30 h-10 ${
                state.status === 'connected' ? 'btn-delete' : 'btn-primary'
              }`}
              disabled={state.status === 'connecting' || !deviceId}
            >
              {state.status === 'disconnected' ? 'Connect' : 'Disconnect'}
            </button>
          </div>
        </div>
      </div>
      <div className='w-2/5 flex flex-col h-full gap-4'>
        <div className='w-full flex flex-col border border-secondary rounded-xl p-2 h-80 flex-shrink-0'>
          <label className='font-bold'>Instructions</label>
          <textarea
            className='field w-full h-full resize-none'
            disabled={!editingInstructions}
            defaultValue={state.instructions}
            onChange={(e) => api.updateInstructions(e.target.value)}
          />
          <div>
            {editingInstructions ? (
              <button
                type='button'
                className='text-primary underline'
                onClick={() => {
                  api.syncSessionInstructions();
                  setEditingInstructions(false);
                }}
              >
                Save
              </button>
            ) : (
              <button
                type='button'
                className='text-primary underline'
                onClick={() => setEditingInstructions(true)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
        <div className='w-full flex flex-col border border-secondary rounded-xl p-2 flex-grow overflow-y-auto overflow-x-hidden'>
          <label className='font-bold'>Events</label>
          <div
            ref={eventsRef}
            className='overflow-y-auto overflow-x-hidden font-mono'
          >
            {state.events.map((e) => (
              <EventEntry
                event={e}
                key={e.event.event_id}
                expand={state.expanedEventIds.includes(e.event.event_id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

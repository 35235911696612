import { RealtimeClient } from '@openai/realtime-api-beta';
import { type ItemType } from '@openai/realtime-api-beta/dist/lib/client';
import { useMemo } from 'react';
import { proxy } from 'valtio';

import config from '../../config';
import { getLogger } from '../../logger/logger';
import { createProvider } from '../../utils/createProvider';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../utils/valtio';
import { WavRecorder, WavStreamPlayer } from '../../vendor/wavtools';

const DEFAULT_INSTRUCTIONS = `System settings:
Tool use: disabled.

Instructions:
- You are an artificial intelligence agent responsible for helping test realtime voice capabilities
- Please make sure to respond with a helpful voice via audio
- Be kind, helpful, and curteous
- It is okay to ask the user questions
- Use tools and functions you have available liberally, it is part of the training apparatus
- Be open to exploration and conversation
- Remember: this is just for fun and testing!

Personality:
- Be upbeat and genuine
- Try speaking quickly as if excited
`;

export type RealtimeEvent = {
  time: string;
  source: 'client' | 'server';
  count?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: { [key: string]: any };
};

type EventConversationUpdatedPayload = {
  item: {
    id: ItemType['id'];
    status: 'completed' | 'in_progress' | 'incomplete';
    formatted: ItemType['formatted'];
  };
  delta: {
    audio?: Int16Array | ArrayBuffer;
  };
};

export type TurnEndMode = 'push_to_talk' | 'server_vad';

type PlainItem = Pick<ItemType, 'id' | 'type' | 'role' | 'object'> & {
  key: string;
};

type State = {
  items: PlainItem[];
  events: RealtimeEvent[];
  expanedEventIds: string[];
  instructions: string;
  status: 'connecting' | 'connected' | 'disconnected';
  isRecording: boolean;
  turnEndMode: TurnEndMode;
  t0: string;
};

function toPlainItem(item: ItemType): PlainItem {
  return {
    id: item.id,
    type: item.type,
    role: item.role,
    object: item.object,
    key: `${item.id}#${item.formatted.file?.url ?? 'none'}`,
  };
}

class VoiceChatAPI {
  private client;
  private wavRecorder;
  private wavStreamPlayer;
  private _state = markSnapshottable(proxy<State>(this.initialState()));
  private itemLookupMap = new Map<string, ItemType>();
  constructor(
    baseUrl = config.api.baseUrl,
    path = '/openai/realtime',
    readonly log = getLogger().scoped('voice-chat')
  ) {
    const url = new URL(baseUrl);
    if (url.protocol === 'http:') {
      url.protocol = 'ws:';
    } else if (url.protocol === 'https:') {
      url.protocol = 'wss:';
    } else {
      throw new Error(`Unsupported protocol: ${url.protocol}`);
    }
    url.pathname += path;
    this.client = new RealtimeClient({ url: url.toString() });
    this.wavRecorder = new WavRecorder({ sampleRate: 24000 });
    this.wavStreamPlayer = new WavStreamPlayer({ sampleRate: 24000 });
  }

  get state(): ValtioSnapshottable<State> {
    return this._state;
  }

  getItem(id: string) {
    return this.itemLookupMap.get(id);
  }

  init() {
    // Set instructions
    this.client.updateSession({ instructions: this._state.instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    this.client.updateSession({
      input_audio_transcription: { model: 'whisper-1' },
    });

    // handle realtime events from client + server for event logging
    this.client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      const lastEvent: RealtimeEvent | undefined =
        this._state.events[this._state.events.length - 1];
      if (lastEvent?.event.type === realtimeEvent.event.type) {
        // if we receive multiple events in a row, aggregate them for display purposes
        lastEvent.count = (lastEvent.count || 0) + 1;
        this._state.events.slice(0, -1).push(lastEvent);
      } else {
        this._state.events.push(realtimeEvent);
      }
    });

    this.client.on('error', (event: unknown) => {
      this.log.error('Error', event);
    });

    this.client.on('conversation.interrupted', async () => {
      const trackSampleOffset = this.wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        this.client.cancelResponse(trackId, offset);
      }
    });

    this.client.on(
      'conversation.updated',
      async ({ item, delta }: EventConversationUpdatedPayload) => {
        const items = this.client.conversation.getItems();
        if (delta?.audio) {
          this.wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        if (item.status === 'completed' && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        }
        this.updateItems(items);
      }
    );

    return () => {
      this.client.reset();
    };
  }

  async connect(micDeviceId?: string, initialMessage?: string) {
    this._state.t0 = new Date().toISOString();
    this._state.status = 'connecting';
    this.updateItems(this.client.conversation.getItems());
    try {
      // Connect to microphone
      const wavRecorderConnected = await this.wavRecorder.begin(micDeviceId);
      // Connect to audio output
      const wavStreamPlayerConnected = await this.wavStreamPlayer.connect();
      // Connect to realtime API
      const clientConnected = await this.client.connect();
      this.log.info('connected', {
        wavRecorderConnected,
        wavStreamPlayerConnected,
        clientConnected,
      });
      this._state.status = 'connected';
    } catch (error) {
      this._state.status = 'disconnected';
      this.log.error('connecting error', error);
      throw error;
    }
    if (initialMessage) {
      this.client.sendUserMessageContent([
        {
          type: `input_text`,
          text: initialMessage,
        },
      ]);
    }

    if (this.client.getTurnDetectionType() === 'server_vad') {
      await this.wavRecorder.record((data) =>
        this.client.appendInputAudio(data.mono)
      );
    }
  }

  async disconnect() {
    this.client.disconnect();
    await this.wavRecorder.end();
    await this.wavStreamPlayer.interrupt();
    this._state.status = 'disconnected';
  }

  async startRecording() {
    this._state.isRecording = true;
    const trackSampleOffset = await this.wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await this.client.cancelResponse(trackId, offset);
    }
    await this.wavRecorder.record((data) =>
      this.client.appendInputAudio(data.mono)
    );
  }

  async stopRecording() {
    this._state.isRecording = false;
    await this.wavRecorder.pause();
    this.client.createResponse();
  }

  async changeTurnEndMode(value: State['turnEndMode']) {
    if (
      value === 'push_to_talk' &&
      this.wavRecorder.getStatus() === 'recording'
    ) {
      await this.wavRecorder.pause();
    }
    this.client.updateSession({
      turn_detection: value === 'push_to_talk' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && this.client.isConnected()) {
      await this.wavRecorder.record((data) =>
        this.client.appendInputAudio(data.mono)
      );
    }
    this._state.turnEndMode = value;
  }

  formatTime(timestamp: string) {
    const startTime = this._state.t0;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }

  toggleExpandEvent(eventId: string) {
    if (this._state.expanedEventIds.includes(eventId)) {
      this._state.expanedEventIds = this._state.expanedEventIds.filter(
        (id) => id !== eventId
      );
    } else {
      this._state.expanedEventIds.push(eventId);
    }
  }

  updateInstructions(instructions: string) {
    this._state.instructions = instructions;
  }

  syncSessionInstructions() {
    this.client.updateSession({ instructions: this._state.instructions });
  }

  private updateItems(items: ItemType[]) {
    this.itemLookupMap.clear();
    const plainItems: PlainItem[] = [];
    for (const item of items) {
      this.itemLookupMap.set(item.id, item);
      plainItems.push(toPlainItem(item));
    }
    this._state.items = plainItems;
  }

  private initialState(): State {
    return {
      items: [],
      events: [],
      expanedEventIds: [],
      instructions: DEFAULT_INSTRUCTIONS,
      status: 'disconnected',
      isRecording: false,
      turnEndMode: 'push_to_talk',
      t0: new Date().toISOString(),
    };
  }
}

const { Provider, useCreatedContext } =
  createProvider<VoiceChatAPI>('VoiceChatAPI');

export function useVoiceChatAPI() {
  return useCreatedContext();
}

export function useVoiceChatState() {
  return useSnapshot(useCreatedContext().state);
}

export function VoiceChatProvider(props: { children?: React.ReactNode }) {
  const instance = useMemo(() => new VoiceChatAPI(), []);

  return <Provider value={instance}>{props.children}</Provider>;
}
